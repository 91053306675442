import { OfferCategoryType } from '@alao-frontend/core'

export const OFFERS_LIST_COOKIE_NAME = 'alao_offer_list'
export const OFFERS_POSITION_COOKIE_NAME = 'alao_offer_position'

export type EventPayload<
  T extends Record<string, any> = Record<string, any>,
> = T

export type TrackingProvider = {
  push: (...args: unknown[]) => void,
}

export type GoogleTrackingProvider = TrackingProvider & {
  push: (...args: unknown[]) => void,
}

export interface TrackEventOptions {
  provider: TrackingProvider,
  debug: boolean,
}

export enum AnalyticsOffersList {
  CATALOG = 'Catalog',
  MAIN_EXCLUSIVE_OFFER = 'Main Exclusive Offer',
  ADDITIONAL_EXCLUSIVE_OFFER = 'Additional Exclusive Offers',
  ALTERNATIVE_OFFER = 'Alternative Offer – Negative Credit Score Check',
  DIRECT_LINK = 'Direct Link',
}

export enum AnalyticsVPVOfferTypes {
  MOBILE_ABO_OFFER = 'mobile-offer',
  HOME_OFFER = 'home-offer',
  BUNDLE_OFFER = 'bundle-offer',
  DEVICE_WITH_MOBILE_PLAN_OFFER = 'device-with-mobile-plan-offer',
}

export enum AnalyticsReadableOfferTypes {
  MOBILE_ABO_OFFER = 'Mobile',
  HOME_OFFER = 'Home',
  BUNDLE_OFFER = 'Bundle',
  DEVICE_WITH_MOBILE_PLAN_OFFER = 'Device offer with mobile plan',
}

export type PageViewEventMeta = {
  routeName: string,
  pageTitle: string,
  fullPath: string,
  fromPath: string,
}

export type OSPPageViewEventMeta = {
  routeName: string,
  fromPath: string,
  offerType: OfferCategoryType,
  offerName: string,
  stepPath: string,
  stepName: string,
}

export type PageViewEventPayload = {
  routeName: string,
  pageType: string,
  pageUrl: string,
  fullUrl: string,
  pageTitle: string,
  vpv: string,
  previousUrl: string,
}

export const enum ECommerceEventName {
  VIEW_ITEM_LIST = 'view_item_list',
  SELECT_ITEM = 'select_item',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  VERIFY_ID_VIEW = 'verify_id_view',
  VERIFY_ID_FRONT_SIDE_SUBMITTED = 'verify_id_front_side_submitted',
  VERIFY_ID_REVERSE_SIDE_SUBMITTED = 'verify_id_reverse_side_submitted',
  VERIFY_ID_SELFIE_SUBMITTED = 'verify_id_selfie_submitted',
  ADD_PAYMENT_INFO = 'add_payment_info',
  VERIFY_ID_SUCCESSFUL = 'verify_id_successful',
  VERIFY_ID_FAIL = 'verify_id_fail',
  PURCHASE = 'purchase',
}

export const enum ECommerceEventAction {
  PRODUCT_IMPRESSION = 'product-impression',
  PRODUCT_CLICK = 'product-click',
  PRODUCT_DETAILS = 'product-details',
  PRODUCT_ADD_TO_CART = 'product-add-to-cart',
  PRODUCT_CHECKOUT_2 = 'product-checkout-2', // Yep, it starts from 2nd
  PRODUCT_CHECKOUT_3 = 'product-checkout-3',
  PRODUCT_CHECKOUT_4 = 'product-checkout-4',
  PRODUCT_CHECKOUT_5 = 'product-checkout-5',
  PRODUCT_CHECKOUT_6 = 'product-checkout-6',
  PRODUCT_CHECKOUT_7 = 'product-checkout-7',
  PRODUCT_PURCHASE = 'product-purchase',
}

export type WithEventProperty<P extends Record<string, any>> = {
  event: string,
} & P

// TODO: Define the payload type
export type ECommerceEventPayload = Record<string, any>
