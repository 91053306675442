
import { Component, Emit, Vue } from 'nuxt-property-decorator'
import { NotificationType } from '@alao-frontend/ui/src/lib-components/AlaoNotification/types'
import { getCookie, setCookie } from '@alao-frontend/utils'
import SaveEmailForm from './SaveEmailForm.vue'
import AppModal from '~/components/AppModal/AppModal.vue'
import { OnMounted } from '~/core/vue.types'
import { $api } from '~/plugins/axios'
import { NotifiesOfferType } from '~/services/notifier.service'
import { FeatureFlags } from '~/core/config/feature-flags'

@Component({
  components: {
    AppModal,
    SaveEmailForm,
  },
})
export default class ExitModal extends Vue implements OnMounted {
  show = false
  isLoadingEmailForm = false

  readonly featureEasterTheme = this.$featureFlags.isEnabled(FeatureFlags.EASTER_THEME)

  get badgeUrl () {
    if (this.featureEasterTheme) {
      return require('~/assets/images/promos/easter-theme/exit-popup-badge.svg')
    }

    return require('~/assets/images/badges/exit-modal-badge.svg')
  }

  get title () {
    if (this.featureEasterTheme) {
      return this.$t('exitPopup.easterPromo.title').toString()
    }

    return this.$t('exitPopup.regular.title')
  }

  get text () {
    if (this.featureEasterTheme) {
      return this.$t('exitPopup.easterPromo.text').toString()
    }

    return this.$t('exitPopup.regular.text')
  }

  @Emit('on-cancel')
  onModalClose (days:number) {
    this.show = false
    const date = new Date()
    setCookie('isExitBannerHidden', 1, {
      expires: new Date(date.setDate(date.getDate() + days)),
      path: '/',
    })
    document.removeEventListener('mouseleave', this.showModal)
  }

  sendGTMEvent <T extends Record<string, unknown>>(
    eventName: 'popup_shown' | 'popup_email_submit',
    data: T,
  ) {
    this.$analytics.gtm.trackCustomEvent('GTMevent-v2', {
      eventName,
      data,
      _clear: true,
    })
  }

  showModal (event: MouseEvent) {
    if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      this.show = true

      this.sendGTMEvent('popup_shown', {
        event_category: 'popup',
        event_action: 'shown',
        event_label: 'left_sitebox',
        trigger_action: 'left_sitebox',
        language_code: this.$i18n.locale.toUpperCase(),
      })
    }
  }

  mounted () {
    if (process.client && !getCookie('isExitBannerHidden')) {
      document.addEventListener('mouseleave', this.showModal, { once: true })
    }
  }

  async handlerSubmitEmailForm (email: string) {
    try {
      this.isLoadingEmailForm = true
      await $api.NotifierService.subscribe({
        id: email,
        subscribe: true,
        service: 'secretdeals_popup',
        offer_type: NotifiesOfferType.MOBILE_OFFER,
      })
      this.$notify({
        title: this.$t('blackFriday.saveEmail.successNotifyTitle').toString(),
        message: this.$t('blackFriday.saveEmail.successNotifyText').toString(),
        duration: 3000,
        type: NotificationType.SUCCESS,
      })
      this.sendGTMEvent('popup_email_submit', {
        event_category: 'popup',
        event_action: 'email_submit',
        event_label: 'left_sitebox',
        trigger_action: 'left_sitebox',
        language_code: this.$i18n.locale.toUpperCase(),
      })
    } finally {
      this.isLoadingEmailForm = false
      this.onModalClose(180)
    }
  }
}
