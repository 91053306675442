
import { Component, mixins } from 'nuxt-property-decorator'

import { FeatureFlags } from '~/core/config/feature-flags'
import SeoLayoutMixin from '~/layouts/mixins/seo'
import CookieBanner from '~/components/CookieBanner.vue'
import ExitModal from '~/components/ExitPopup/ExitPopup.vue'
import AppLayoutOverlay from '~/components/AppLayoutOverlay/AppLayoutOverlay.vue'
import AppHubspotChat from '~/components/AppHubspotChat.vue'
import AppDixaChat from '~/components/AppDixaChat.vue'
import DealOfTheDay from '~/components/DealOfTheDay/DealOfTheDay.vue'
import AlaoNotification from '~/components/AlaoNotification/AlaoNotification.vue'
import BenefitsFortuneWheelModal from '~/components/BenefitsFortuneWheelModal/BenefitsFortuneWheelModal.vue'
import OnMouseLeaveDisplay from '~/components/OnMouseLeaveDisplay/OnMouseLeaveDisplay.vue'
import OnIdleTimeoutDisplay from '~/components/OnIdleTimeoutDisplay/OnIdleTimeoutDisplay.vue'

@Component({
  components: {
    OnIdleTimeoutDisplay,
    OnMouseLeaveDisplay,
    BenefitsFortuneWheelModal,
    DealOfTheDay,
    AppHubspotChat,
    AppDixaChat,
    AppLayoutOverlay,
    CookieBanner,
    ExitModal,
    AlaoNotification,
  },
})
export default class AppLayout extends mixins(SeoLayoutMixin) {
  isFortuneWheelVisible = false

  readonly featureHubspotChat = this.$featureFlags.isEnabled(FeatureFlags.HUBSPOT_CHAT)
  readonly featureDixaChat = this.$featureFlags.isEnabled(FeatureFlags.DIXA_CHAT)

  get isOpenChatImmediately () {
    return Boolean(this.$route.query.open_chat) || false
  }

  get isDisplayFortuneWheel () {
    const allowedRoutes = [
      'index',
      'compare-mobile',
      'compare-home',
      'compare-bundle',
      'blogs',
      'blogs-slug',
      'provider-slug',
      'black-friday',
      'black-friday-slug',
      'black-friday-mobile-plans',
      'black-friday-internet-plans',
      'about-us',
      'how-it-works',
      'coverage',
      'ratings',
    ]

    // Route name consists of two parts: the name itself and language code (e.g. 'index___en')
    const separator = '___'

    return allowedRoutes.includes((this.$route.name || '').split(separator)[0])
  }

  get isShowDealOfTheDay () {
    const allowedRoutes = [
      'index',
      'compare-mobile',
      'compare-home',
      'compare-bundle',
      'blogs',
      'blogs-slug',
      'provider-slug',
      'black-friday',
      'black-friday-slug',
      'black-friday-mobile-plans',
      'black-friday-internet-plans',
      'about-us',
      'how-it-works',
      'coverage',
      'ratings',
    ]

    // Route name consists of two parts: the name itself and language code (e.g. 'index___en')
    const separator = '___'

    return allowedRoutes.includes((this.$route.name || '').split(separator)[0])
  }
}
