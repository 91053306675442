import { Plugin } from '@nuxt/types'
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { logger } from '~nuxt-feature-flags/logger'
// @ts-ignore
import { FeatureFlagsClient } from '~nuxt-feature-flags/client/feature-flags-client'
// @ts-ignore
import { FeatureFlagsStorage } from '~nuxt-feature-flags/client/feature-flags-storage'

const plugin: Plugin = async (ctx, inject) => {
  const parsedOptions = JSON.parse('{"enabledDefault":false,"ttl":60000,"requestTimeout":5000,"url":"https://gitlab.com/api/v4/feature_flags/unleash/22389486","instanceId":"9pf91JSD4cU78XxLBte3","environment":"prod","projectFeatures":["feature_new_gtm_debug_mode","feature_black_friday","feature_black_september","feature_black_april","feature_summer_deals","feature_valentine_theme","feature_spring_theme","feature_easter_theme","feature_may_theme","feature_summer_theme","feature_new_main_banner","feature_b2b_page","feature_new_verify_id_process","feature_new-year","feature_new-voucher-process","feature_amp_addresses","feature_amp_base_info","feature_amp_comparator","feature_hubspot_chat","feature_dixa_chat"],"baseURL":"https://www.alao.ch","useRedisStore":false,"redisConnection":{"socket":{"host":"alao-frontend-redis","port":6379,"connectTimeout":3000},"database":1,"disableOfflineQueue":true}}')

  const storage = FeatureFlagsStorage.create()
  const featureFlags = await FeatureFlagsClient.create(parsedOptions, storage, logger).bootstrap()

  inject('featureFlags', featureFlags)
  ctx.app.featureFlags = featureFlags

  // For debugging purposes
  if (process.client) {
    (window as any).featureFlags = () => {
      const features = parsedOptions.projectFeatures.map((featureName: string) => {
        const feature = featureFlags.list.find((item: any) => item.name === featureName)

        if (feature) {
          return {
            name: feature.name,
            enabled: feature.enabled,
            description: feature.description,
          }
        }

        return {
          name: featureName,
          enabled: parsedOptions.enabledDefault,
          description: null,
        }
      })

      console.table(features)
    }
  }
}

export default plugin
