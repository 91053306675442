
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import { GRWidgetState, namespace as grWidgetStore } from '~/store/modules/GRWidget'
import IconGoogle from '~/components/icons/IconGoogle.vue'

const GRWidgetStore = namespace(grWidgetStore)

@Component({
  components: {
    IconGoogle,
  },
})
export default class GoogleRatingLite extends Vue {
  @GRWidgetStore.State('isLoading') isLoading!: GRWidgetState['isLoading']
  @GRWidgetStore.State('isLoaded') isLoaded!: GRWidgetState['isLoaded']
  @GRWidgetStore.State('rating') rating!: GRWidgetState['rating']
  @GRWidgetStore.State('reviews') reviews!: GRWidgetState['reviews']

  get ratingRounded (): number {
    return Math.ceil(this.rating)
  }
}
